<template>
  <div class="dyeingAndFinishingOrderDetail">
    <div class="header">
      <div class="header_title">
        <div class="block"></div>
        <div>跟单详情</div>
      </div>
      <div class="header_message">
        <div class="header_right">
          <div>当前状态</div>
          <div>{{chooseStatus()}}</div>
        </div>
        <div class="header_right">
          <div>跟单员</div>
          <div>{{$route.query.followerName}}</div>
        </div>
      </div>
    </div>
    <div class="steps">
      <!-- 坯布确认 -->
      <div class="grey">
        <div class="steps_title">1.坯布确认</div>
        <table cellspacing="0" class="steps_table">
          <tr>
            <td class="greyBackground">订单号</td>
            <td>{{(grepclothComfir&&grepclothComfir.ztdpoContractNo)?grepclothComfir.ztdpoContractNo:'-'}}</td>
            <td class="greyBackground">货号</td>
            <td>{{(grepclothComfir&&grepclothComfir.ztdpoArticleNumber)?grepclothComfir.ztdpoArticleNumber:'-'}}</td>
            <td class="greyBackground">坯布货号</td>
            <td>{{(grepclothComfir&&grepclothComfir.ztdpoGreyFabricNumber1)?grepclothComfir.ztdpoGreyFabricNumber1:'-'}}</td>
            <td class="greyBackground">加工单号</td>
            <td colspan="2">{{(grepclothComfir&&grepclothComfir.ztdpoDyeingOrderNumber)?grepclothComfir.ztdpoDyeingOrderNumber:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground">染厂</td>
            <td>{{(grepclothComfir&&grepclothComfir.ztdpoCompanyName)?grepclothComfir.ztdpoCompanyName:'-'}}</td>
            <td class="greyBackground">制单日期</td>
            <td>{{(grepclothComfir&&grepclothComfir.ztdpoFormDate)?grepclothComfir.ztdpoFormDate:'-'}}</td>
            <td class="greyBackground">交货日期</td>
            <td>{{(grepclothComfir&&grepclothComfir.ztdpoOrderDeliveryDate)?grepclothComfir.ztdpoOrderDeliveryDate:'-'}}</td>
            <td class="greyBackground">条距</td>
            <td colspan="2">{{(grepclothComfir&&grepclothComfir.ztdpoBarSpacing)?grepclothComfir.ztdpoBarSpacing:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground">品名</td>
            <td>{{(grepclothComfir&&grepclothComfir.ztdpoProductName)?grepclothComfir.ztdpoProductName:'-'}}</td>
            <td class="greyBackground">门幅</td>
            <td>{{(grepclothComfir&&grepclothComfir.ztdgcpLarghezza)?grepclothComfir.ztdgcpLarghezza:'-'}}</td>
            <td class="greyBackground">克重</td>
            <td>{{(grepclothComfir&&grepclothComfir.ztdgcpGram)?grepclothComfir.ztdgcpGram:'-'}}</td>
            <td class="greyBackground">确认条距</td>
            <td colspan="3">{{(grepclothComfir&&grepclothComfir.ztdgcpSliverSpacing)?grepclothComfir.ztdgcpSliverSpacing:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground">成分</td>
            <td colspan="8">{{(grepclothComfir&&grepclothComfir.ztdpoIngredients)?grepclothComfir.ztdpoIngredients:'-'}}</td>
          </tr>
            <tr>
              <td class="greyBackground" rowspan="2">工厂颜色</td>
              <td rowspan="2">{{(grepclothComfir&&grepclothComfir.ztdpoiFactoryColor)?grepclothComfir.ztdpoiFactoryColor:'-'}}</td>
              <td class="greyBackground" rowspan="2">工厂色号</td>
              <td rowspan="2">{{(grepclothComfir&&grepclothComfir.ztdpoiFactoryColorNumber)?grepclothComfir.ztdpoiFactoryColorNumber:'-'}}</td>
              <td class="greyBackground" rowspan="2">需求数(KGS)</td>
              <td rowspan="2">{{(grepclothComfir&&grepclothComfir.ztdpoiContractWeight)?grepclothComfir.ztdpoiContractWeight:'-'}}</td>
              <td class="greyBackground" rowspan="2">投坯数量</td>
              <td class="greyBackground">匹数(匹)</td>
              <td>{{(grepclothComfir&&grepclothComfir.ztdpoiClothQuantity)?grepclothComfir.ztdpoiClothQuantity:'-'}}</td>
            </tr>
            <tr>
              <td class="greyBackground">重量(KG)</td>
              <td>{{(grepclothComfir&&grepclothComfir.ztdpoiPutInWeight)?grepclothComfir.ztdpoiPutInWeight:'-'}}</td>
            </tr>
        </table>
      </div>
      <!-- 预定 -->
      <div class="reserve">
        <div class="steps_title">2.预定</div>
        <table cellspacing="0" class="steps_table">
          <tr>
            <td class="greyBackground">车速</td>
            <td colspan="3">{{(yuDingInfo&&yuDingInfo.ztdypMachineSpeed)?yuDingInfo.ztdypMachineSpeed:'-'}}</td>
            <td class="greyBackground">温度</td>
            <td colspan="4">{{(yuDingInfo&&yuDingInfo.ztdypMachineTemperature)?yuDingInfo.ztdypMachineTemperature:'-'}}</td>
          </tr>
          <template v-for="(item,index) in JSON.parse(yuDingInfo.ztdypMachineAuxiliary)">
          <tr :key="item.auxiliary">
            <td class="greyBackground">助剂{{index+1}}</td>
            <td colspan="3">{{item.auxiliary?item.auxiliary:'-'}}</td>
            <td class="greyBackground">助剂{{index+1}}信息</td>
            <td colspan="4">{{item.auxiliaryInfo?item.auxiliaryInfo:'-'}}</td>
          </tr>
          </template>
          <tr>
            <td class="greyBackground" rowspan="2">上机</td>
            <td class="greyBackground">门幅(cm)</td>
            <td>{{(yuDingInfo&&yuDingInfo.ztdypUpMachineLarghezza)?yuDingInfo.ztdypUpMachineLarghezza:'-'}}</td>
            <td class="greyBackground" rowspan="2">定中</td>
            <td class="greyBackground">门幅(cm)</td>
            <td>{{(yuDingInfo&&yuDingInfo.ztdypMiddleMachineLarghezza)?yuDingInfo.ztdypMiddleMachineLarghezza:'-'}}</td>
            <td class="greyBackground" rowspan="2">下机</td>
            <td class="greyBackground">门幅(cm)</td>
            <td>{{(yuDingInfo&&yuDingInfo.ztdypDownMachineLarghezza)?yuDingInfo.ztdypDownMachineLarghezza:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground">克重(GSM)</td>
            <td>{{(yuDingInfo&&yuDingInfo.ztdypUpMachineGram)?yuDingInfo.ztdypUpMachineGram:'-'}}</td>
            <td class="greyBackground">克重(GSM)</td>
            <td>{{(yuDingInfo&&yuDingInfo.ztdypMiddleMachineGram)?yuDingInfo.ztdypMiddleMachineGram:'-'}}</td>
            <td class="greyBackground">克重(GSM)</td>
            <td>{{(yuDingInfo&&yuDingInfo.ztdypDownMachineLarghezza)?yuDingInfo.ztdypDownMachineLarghezza:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground" rowspan="2">图片</td>
            <td colspan="8" rowspan="2">
              <img v-for="item in (imgList&&imgList.imgList_yd)?imgList.imgList_yd:[]" :key="item" :src="baseUrl+item"/>
            </td>
          </tr>
          <tr></tr>
          <tr>
            <td class="greyBackground">备注</td>
            <td colspan="8 ">{{(yuDingInfo&&yuDingInfo.ztdypRemarks)?yuDingInfo.ztdypRemarks:'-'}}</td>
          </tr>
        </table>
      </div>
      <!-- 成定 -->
      <div class="chengDing">
        <div class="steps_title">3.成定</div>
        <table cellspacing="0" class="steps_table">
          <tr>
            <td class="greyBackground">车速</td>
            <td colspan="4">{{(chengDingInfo&&chengDingInfo.ztdcpMachineSpeed)?chengDingInfo.ztdcpMachineSpeed:'-'}}</td>
            <td class="greyBackground">温度</td>
            <td colspan="5">{{(chengDingInfo&&chengDingInfo.ztdcpMachineTemperature)?chengDingInfo.ztdcpMachineTemperature:'-'}}</td>
          </tr>
          <template v-for="(item,index) in JSON.parse(chengDingInfo.ztdcpMachineAuxiliary)">
            <tr :key="item.auxiliary">
              <td class="greyBackground">助剂{{index+1}}</td>
              <td colspan="4">{{item.auxiliary?item.auxiliary:'-'}}</td>
              <td class="greyBackground">助剂{{index+1}}信息</td>
              <td colspan="5">{{item.auxiliaryInfo?item.auxiliaryInfo:'-'}}</td>
            </tr>
          </template>
          <tr>
            <td class="greyBackground" rowspan="2">上机</td>
            <td class="greyBackground">门幅(cm)</td>
            <td colspan="2">{{(chengDingInfo&&chengDingInfo.ztdcpUpMachineLarghezza)?chengDingInfo.ztdcpUpMachineLarghezza:'-'}}</td>
            <td class="greyBackground" rowspan="2">定中</td>
            <td class="greyBackground">门幅(cm)</td>
            <td colspan="2">{{(chengDingInfo&&chengDingInfo.ztdcpMiddleMachineLarghezza)?chengDingInfo.ztdcpMiddleMachineLarghezza:'-'}}</td>
            <td class="greyBackground" rowspan="2">下机</td>
            <td class="greyBackground">门幅(cm)</td>
            <td colspan="2">{{(chengDingInfo&&chengDingInfo.ztdcpDownMachineLarghezza)?chengDingInfo.ztdcpDownMachineLarghezza:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground">克重(GSM)</td>
            <td colspan="2">{{(chengDingInfo&&chengDingInfo.ztdcpUpMachineGram)?chengDingInfo.ztdcpUpMachineGram:'-'}}</td>
            <td class="greyBackground">克重(GSM)</td>
            <td colspan="2">{{(chengDingInfo&&chengDingInfo.ztdcpMiddleMachineGram)?chengDingInfo.ztdcpMiddleMachineGram:'-'}}</td>
            <td class="greyBackground">克重(GSM)</td>
            <td colspan="2">{{(chengDingInfo&&chengDingInfo.ztdcpDownMachineGram)?chengDingInfo.ztdcpDownMachineGram:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground" rowspan="2">图片</td>
            <td colspan="10" rowspan="2">
              <img v-for="item in (imgList&&imgList.imgList_cd)?imgList.imgList_cd:[]" :key="item" :src="baseUrl+item" alt=""/>
            </td>
          </tr>
          <tr></tr>
          <tr>
            <td class="greyBackground">备注</td>
            <td colspan="10">{{(chengDingInfo&&chengDingInfo.ztdcpOtherRemarks)?chengDingInfo.ztdcpOtherRemarks:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground">缩率要求</td>
            <td class="greyBackground">直缩(%)</td>
            <td>{{(chengDingInfo&&chengDingInfo.ztdcpStraightContraction)?chengDingInfo.ztdcpStraightContraction:'-'}}</td>
            <td class="greyBackground">横缩(%)</td>
            <td>{{(chengDingInfo&&chengDingInfo.ztdcpTransverseContraction)?chengDingInfo.ztdcpTransverseContraction:'-'}}</td>
            <td class="greyBackground">平纹(%)</td>
            <td>{{(chengDingInfo&&chengDingInfo.ztdcpPlainWeave)?chengDingInfo.ztdcpPlainWeave:'-'}}</td>
            <td class="greyBackground">扭度(%)</td>
            <td>{{(chengDingInfo&&chengDingInfo.ztdcpTwist)?chengDingInfo.ztdcpTwist:'-'}}</td>
            <td class="greyBackground">PH值</td>
            <td>{{(chengDingInfo&&chengDingInfo.ztdcpPh)?chengDingInfo.ztdcpPh:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground">色牢度要求</td>
            <td class="greyBackground">水洗(级)</td>
            <td>{{chooseGrade((chengDingInfo&&chengDingInfo.ztdcpWashing)?chengDingInfo.ztdcpWashing:'-')}}</td>
            <td class="greyBackground">干磨(级)</td>
            <td>{{chooseGrade((chengDingInfo&&chengDingInfo.ztdcpDryFriction)?chengDingInfo.ztdcpDryFriction:'-')}}</td>
            <td class="greyBackground">湿磨(级)</td>
            <td>{{chooseGrade((chengDingInfo&&chengDingInfo.ztdcpWetFriction)?chengDingInfo.ztdcpWetFriction:'-')}}</td>
            <td class="greyBackground">光照(级)</td>
            <td>{{chooseGrade((chengDingInfo&&chengDingInfo.ztdcpIllumination)?chengDingInfo.ztdcpIllumination:'-')}}</td>
            <td class="greyBackground">汗渍(级)</td>
            <td>{{chooseGrade((chengDingInfo&&chengDingInfo.ztdcpSweatStain)?chengDingInfo.ztdcpSweatStain:'-')}}</td>
          </tr>
          <tr>
            <td class="greyBackground">手感要求</td>
            <td colspan="4">{{(chengDingInfo&&chengDingInfo.ztdcpHandleRequirements)?chengDingInfo.ztdcpHandleRequirements:'-'}}</td>
            <td class="greyBackground">对色光源</td>
            <td colspan="5">{{(chengDingInfo&&chengDingInfo.ztdcpColorSource)?chengDingInfo.ztdcpColorSource:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground">合理损耗及计算方法</td>
            <td colspan="10">{{(chengDingInfo&&chengDingInfo.ztdcpReasonableLoss)?chengDingInfo.ztdcpReasonableLoss:'-'}}</td>
          </tr>
        </table>
      </div>
      <!-- 业务确认 -->
      <div class="businessConfirm">
        <div class="steps_title">4.业务确认</div>
        <table cellspacing="0" class="steps_table">
          <tr>
            <td class="greyBackground" style="width:220px">审核结果</td>
            <td colspan="9">{{chooseConfirm((businessConfirmInfo&&businessConfirmInfo.status)?businessConfirmInfo.status:'-')}}</td>
          </tr>
          <tr>
            <td class="greyBackground" style="width:220px">审核意见</td>
            <td colspan="9">{{(businessConfirmInfo&&businessConfirmInfo.ztdfoBusinessReviewComments)?businessConfirmInfo.ztdfoBusinessReviewComments:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground" rowspan="2"  style="width:220px">照片</td>
            <td rowspan="2" colspan="9">
              <img v-for="item in (imgList&&imgList.imgList_bc)?imgList.imgList_bc:[]" :key="item" :src="baseUrl+item" alt=""/>
            </td>
          </tr>
          <tr></tr>
        </table>
      </div>
      <!-- 二次成定 -->
      <div class="chengDing" v-if="chengDingInfo_second">
        <div class="steps_title">5.二次成定</div>
        <table cellspacing="0" class="steps_table">
          <tr>
            <td class="greyBackground">车速</td>
            <td colspan="4">{{(chengDingInfo_second&&chengDingInfo_second.ztdcpMachineSpeed)?chengDingInfo_second.ztdcpMachineSpeed:'-'}}</td>
            <td class="greyBackground">温度</td>
            <td colspan="5">{{(chengDingInfo_second&&chengDingInfo_second.ztdcpMachineTemperature)?chengDingInfo_second.ztdcpMachineTemperature:'-'}}</td>
          </tr>
          <template v-for="(item,index) in JSON.parse(chengDingInfo_second.ztdcpMachineAuxiliary)">
          <tr :key="item">
            <td class="greyBackground">助剂{{index+1}}</td>
            <td colspan="4">{{item.auxiliary?item.auxiliary:'-'}}</td>
            <td class="greyBackground">助剂{{index+1}}信息</td>
            <td colspan="5">{{item.auxiliaryInfo?item.auxiliaryInfo:'-'}}</td>
          </tr>
          </template>
          <tr>
            <td class="greyBackground" rowspan="2">上机</td>
            <td class="greyBackground">门幅(cm)</td>
            <td colspan="2">{{(chengDingInfo_second&&chengDingInfo_second.ztdcpUpMachineLarghezza)?chengDingInfo_second.ztdcpUpMachineLarghezza:'-'}}</td>
            <td class="greyBackground" rowspan="2">定中</td>
            <td class="greyBackground">门幅(cm)</td>
            <td colspan="2">{{(chengDingInfo_second&&chengDingInfo_second.ztdcpMiddleMachineLarghezza)?chengDingInfo_second.ztdcpMiddleMachineLarghezza:'-'}}</td>
            <td class="greyBackground" rowspan="2">下机</td>
            <td class="greyBackground">门幅(cm)</td>
            <td colspan="2">{{(chengDingInfo_second&&chengDingInfo_second.ztdcpDownMachineLarghezza)?chengDingInfo_second.ztdcpDownMachineLarghezza:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground">克重(GSM)</td>
            <td colspan="2">{{(chengDingInfo_second&&chengDingInfo_second.ztdcpUpMachineGram)?chengDingInfo_second.ztdcpUpMachineGram:'-'}}</td>
            <td class="greyBackground">克重(GSM)</td>
            <td colspan="2">{{(chengDingInfo_second&&chengDingInfo_second.ztdcpMiddleMachineGram)?chengDingInfo_second.ztdcpMiddleMachineGram:'-'}}</td>
            <td class="greyBackground">克重(GSM)</td>
            <td colspan="2">{{(chengDingInfo_second&&chengDingInfo_second.ztdcpDownMachineGram)?chengDingInfo_second.ztdcpDownMachineGram:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground" rowspan="2">图片</td>
            <td colspan="10" rowspan="2">
              <img v-for="item in (imgList&&imgList.imgList_cd_second)?imgList.imgList_cd_second:[]" :key="item" :src="baseUrl+item" alt=""/>
            </td>
          </tr>
          <tr></tr>
          <tr>
            <td class="greyBackground">备注</td>
            <td colspan="10">{{(chengDingInfo_second&&chengDingInfo_second.ztdcpOtherRemarks)?chengDingInfo_second.ztdcpOtherRemarks:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground">缩率要求</td>
            <td class="greyBackground">直缩(%)</td>
            <td>{{(chengDingInfo_second&&chengDingInfo_second.ztdcpStraightContraction)?chengDingInfo_second.ztdcpStraightContraction:'-'}}</td>
            <td class="greyBackground">横缩(%)</td>
            <td>{{(chengDingInfo_second&&chengDingInfo_second.ztdcpTransverseContraction)?chengDingInfo_second.ztdcpTransverseContraction:'-'}}</td>
            <td class="greyBackground">平纹(%)</td>
            <td>{{(chengDingInfo_second&&chengDingInfo_second.ztdcpPlainWeave)?chengDingInfo_second.ztdcpPlainWeave:'-'}}</td>
            <td class="greyBackground">扭度(%)</td>
            <td>{{(chengDingInfo_second&&chengDingInfo_second.ztdcpTwist)?chengDingInfo_second.ztdcpTwist:'-'}}</td>
            <td class="greyBackground">PH值</td>
            <td>{{(chengDingInfo_second&&chengDingInfo_second.ztdcpPh)?chengDingInfo_second.ztdcpPh:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground">色牢度要求</td>
            <td class="greyBackground">水洗(级)</td>
            <td>{{chooseGrade((chengDingInfo_second&&chengDingInfo_second.ztdcpWashing)?chengDingInfo_second.ztdcpWashing:'-')}}</td>
            <td class="greyBackground">干磨(级)</td>
            <td>{{chooseGrade((chengDingInfo_second&&chengDingInfo_second.ztdcpDryFriction)?chengDingInfo_second.ztdcpDryFriction:'-')}}</td>
            <td class="greyBackground">湿磨(级)</td>
            <td>{{chooseGrade((chengDingInfo_second&&chengDingInfo_second.ztdcpWetFriction)?chengDingInfo_second.ztdcpWetFriction:'-')}}</td>
            <td class="greyBackground">光照(级)</td>
            <td>{{chooseGrade((chengDingInfo_second&&chengDingInfo_second.ztdcpIllumination)?chengDingInfo_second.ztdcpIllumination:'-')}}</td>
            <td class="greyBackground">汗渍(级)</td>
            <td>{{chooseGrade((chengDingInfo_second&&chengDingInfo_second.ztdcpSweatStain)?chengDingInfo_second.ztdcpSweatStain:'-')}}</td>
          </tr>
          <tr>
            <td class="greyBackground">手感要求</td>
            <td colspan="4">{{(chengDingInfo_second&&chengDingInfo_second.ztdcpHandleRequirements)?chengDingInfo_second.ztdcpHandleRequirements:'-'}}</td>
            <td class="greyBackground">对色光源</td>
            <td colspan="5">{{(chengDingInfo_second&&chengDingInfo_second.ztdcpColorSource)?chengDingInfo_second.ztdcpColorSource:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground">合理损耗及计算方法</td>
            <td colspan="10">{{(chengDingInfo_second&&chengDingInfo_second.ztdcpReasonableLoss)?chengDingInfo_second.ztdcpReasonableLoss:'-'}}</td>
          </tr>
        </table>
      </div>
    <!-- 二次业务确认 -->
      <div class="businessConfirm" v-if="chengDingInfo_second">
        <div class="steps_title">6.二次业务确认</div>
        <table cellspacing="0" class="steps_table">
          <tr>
            <td class="greyBackground" :style="businessConfirmInfo_second.status===2?'width:220px;color:#FF6D26;':'width:220px;'">审核结果</td>
            <td colspan="9">{{chooseConfirm((businessConfirmInfo_second&&businessConfirmInfo_second.status)?businessConfirmInfo_second.status:'-')}}</td>
          </tr>
          <tr>
            <td class="greyBackground"  style="width:220px">审核意见</td>
            <td colspan="9">{{(businessConfirmInfo_second&&businessConfirmInfo_second.ztdfoBusinessReviewComments)?businessConfirmInfo_second.ztdfoBusinessReviewComments:'-'}}</td>
          </tr>
          <tr>
            <td class="greyBackground"  style="width:220px" rowspan="2">照片</td>
            <td rowspan="2" colspan="9">
              <img v-for="item in (imgList&&imgList.imgList_bc_second)?imgList.imgList_bc_second:[]" :key="item" :src="baseUrl+item" alt=""/>
            </td>
          </tr>
          <tr></tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      grepclothComfir: {}, // 坯布确认信息
      yuDingInfo: {}, // 预定信息
      chengDingInfo: {}, // 成定信息
      businessConfirmInfo: {}, // 业务信息确认
      chengDingInfo_second: {}, // 二次成定信息
      businessConfirmInfo_second: {}, // 二次业务确认
      imgList: {}, // 图片数组
    }
  },
  created(){
    this.getOrderStatus()
  },
  methods: {
    // 选择级数
    chooseGrade(value){
      switch (value){
        case 1:return '一级';
        case 2:return '二级';
        case 3:return '三级';
        case 4:return '四级';
        case 5:return '五级';
        case 6:return '六级';
        case 7:return '七级';
        case 8:return '八级';
        default:return '-'
      }
    },
    // 选择审核结果
    chooseConfirm(value){
      switch (value){
        case 0:return '待确认';
        case 1:return '通过';
        case 2:return '不通过';
        default:return '-'
      }
    },
    // 选择当前步骤
    chooseStatus(){
      switch (this.$route.query.ztdpoStatus){
        case '0':return '待检验';
        case '1':return '待预定';
        case '2':return '待成定';
        case '3':return '待业务确认';
        case '4':return '待二次成定';
        case '5':return '待二次业务确认';
        case '6':return '已完成';
        default:return '未知'
      }
    },
    // 获取订单详情信息
    getOrderStatus(){
      this.axios({
        url: '/dtsum/zongtong/dyeing/WebDyeingController/dyeingPlanOrderSheetInfo',
        method: 'get',
        params: {
          params: {
            dyeingOrderNumber: this.$route.query.dyeingOrderNumber,
            contractNo: this.$route.query.contract,
            followerName: this.$route.query.followerName,
            ztdpoStatus: this.$route.query.ztdpoStatus
          }
        }
      }).then((res) => {
        if (res.data.success === 1){
          const body = res.data.body
          this.grepclothComfir = JSON.parse(JSON.stringify(body.grepclothComfir))
          this.yuDingInfo = JSON.parse(JSON.stringify(body.yuDingInfo))
          this.chengDingInfo = JSON.parse(JSON.stringify(body.chengDingInfo))
          this.businessConfirmInfo = JSON.parse(JSON.stringify(body.businessConfirmInfo))
          this.chengDingInfo_second = JSON.parse(JSON.stringify(body.chengDingInfo_second))
          this.businessConfirmInfo_second = JSON.parse(JSON.stringify(body.businessConfirmInfo_second))
          this.imgList = JSON.parse(JSON.stringify(body.imgList))
        }
      }).catch((err) => {
        console.log(err)
      })
    },

  }
}
</script>>
<style lang="scss" scoped>
.dyeingAndFinishingOrderDetail {
  font-family: Source Han Sans CN;
  img{
    width:80px;
    // height:80px;
    margin-right:10px;
  }
  img:hover{
  transition-property: transform;
	transition-duration:200ms;
	transition-timing-function: ease-in-out;
	transform: scale(6);
  }
  .header {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    .header_title {
      display: flex;
      align-items: center;
      .block {
        width: 4px;
        height: 20px;
        background: #256de6;
        margin-right: 9px;
      }
    }
    .header_message {
      display: flex;
      justify-content: flex-end;
      .header_right {
        display: flex;
        height: 30px;
        line-height: 30px;
        color: #256de6;
        &:nth-child(1) {
          margin-right: 54px;
        }
        div {
          &:nth-child(1) {
            margin-right: 12px;
            font-size: 14px;
            color: rgba(51, 51, 51, 0.65);
          }
        }
      }
    }
  }
  .steps {
    .steps_title {
      margin-top: 30px;
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    .steps_table {
      width: 100%;
      border: 1px solid #b3a2a2;
      border-collapse: collapse;
      color: rgba(51, 51, 51, 0.85);
      tr {
        height: 60px;
        td {
          padding: 0px 20px;
          border: 1px solid #dcdada;
        }
        .greyBackground {
          background: #f5f5f5;
          font-weight: 500;
          color: #333333;
        }
      }
    }
    .businessConfirm {
      .greyBackground {
        max-width: 170px;
      }
    }
  }
}
</style>
